// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-confirmation-mdx": () => import("./../../../src/pages/confirmation.mdx" /* webpackChunkName: "component---src-pages-confirmation-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---theme-src-templates-step-js": () => import("./../../../../theme/src/templates/step.js" /* webpackChunkName: "component---theme-src-templates-step-js" */)
}

