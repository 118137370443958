import React from "react";
import { Box } from "theme-ui";
import { useLiveFormValues } from "@commonwealth-savings-wizard/theme";
import { animated, useSpring, useTransition } from "@react-spring/web";

const Bar = animated(
  React.forwardRef((props, ref) => (
    <Box ref={ref} as="rect" sx={{ fill: "white" }} {...props} />
  ))
);

const Group = animated(
  React.forwardRef((props, ref) => <Box ref={ref} as="g" {...props} />)
);

const keys = new Map();

const Illustration = () => {
  const formValues = useLiveFormValues(
    [
      "savings-contribution",
      "savings-contribution-other-amount",
      "savings-goal",
      "savings-goal-other-amount",
    ],
    [20, null, 1000]
  );

  const [state, dispatch] = React.useReducer(reducer, {
    items: [],
    fac: 1,
    values: {},
  });
  const prev = React.useRef();

  React.useEffect(() => {
    dispatch(formValues);
  }, [formValues]);

  const [{ scale }, setSpring] = useSpring({ scale: 1 }, []);

  const [transition, animate] = useTransition(
    state.items,
    {
      // ref,
      keys: el => el.key,
      sort: (a, b) => {
        // sort by x except when we've removed items,
        // in which case we reverse direction to animate out
        const isRemoving = prev.current
          ? prev.current.items.length > state.items.length
          : false;
        return a.x < b.x ? -1 : isRemoving || a.x > b.x ? 1 : 0;
      },
      from: (item, index) => ({
        opacity: 0,
        ...getStyle({ state, item, index }),
      }),
      enter: (item, index) => ({
        opacity: 1,
        ...getStyle({ state, item, index }),
      }),
      leave: { opacity: 0 },
      trail: 30,
      config: {
        precision: 0.001,
        clamp: true,
      },
    },
    [state]
  );

  React.useEffect(() => {
    setSpring({ scale: state.fac });
    animate(index => getStyle({ state, index }));
  }, [state, animate, setSpring]);

  return (
    <Box sx={{ width: "100%", height: 0, pb: "100%" }}>
      <Box
        as="svg"
        width="1024"
        height="1024"
        viewBox="0 0 1024 1024"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          strokeWidth: 1.5,
          path: {
            vectorEffect: "non-scaling-stroke",
          },
        }}
      >
        <Blob style={{ scale: scale.to([0, 1.05], [0.6, 1]) }} />
        <Treeline style={{ scale: scale.to([0, 1.05], [0.7, 1]) }} />
        <Boy style={{ scale: scale }} />
        <Mother style={{ scale: scale }} />
        <Group
          sx={{ transformOrigin: "50% 895px" }}
          style={{ scaleX: scale.to([0, 1.05], [1.83, 0.9524]) }}
        >
          {transition((style, item) => (
            <Bar {...item} style={style} />
          ))}
        </Group>
      </Box>
    </Box>
  );
};

export default Illustration;

function reducer(state, formValues) {
  const values = getValues(formValues);
  if (values) {
    const count = getCount(values);
    return {
      items: getItems(count),
      values,
      fac: clamp(10 / count, 0.35, 1.05),
    };
  }

  return state;
}

function getValues(values) {
  let arr = values;
  if (!Array.isArray(values)) {
    arr = Object.entries(values)
      .sort(([a], [b]) => (a < b ? -1 : a > b ? 1 : 0))
      .map(([, v]) => v);
  }

  const [amount, amountOther, goal, goalOther] = arr;

  const next = {
    amount: amount === "Other" ? amountOther : amount,
    goal: goal === "Other" ? goalOther : goal,
  };

  return next.goal && next.amount ? next : undefined;
}

function getCount({ goal, amount }) {
  const period = 7;
  const month = 30;
  const periods = Math.ceil(goal / amount);
  const months = Math.ceil((periods * period) / month);
  return clamp(months, 1, 96);
}

function getItems(n) {
  return Array.apply(null, Array(n)).map((_, i) => ({
    width: 444,
    height: 400,
    x: 290,
    y: 495,
    key: getKey(i),
  }));
}

function getKey(n) {
  if (keys.has(n)) {
    return keys.get(n);
  }

  const key = `key-${n}`;
  keys.set(n, key);

  return key;
}

function getStyle({ state, item, index }) {
  if (!(item || state.items[index])) {
    return {};
  }

  let { height, width, x: oX, y: oY } = item || state.items[index];
  // oX = fac * oX;
  const {
    values: { goal },
    fac,
  } = state;
  const count = state.items.length;

  const gap = Math.max(3, fac * 6);
  const scaleY =
    (fac * ((index + 1) / count) * map(goal, 0, 1500, 100, 420)) / height;
  const scaleX = getColumnWidth({ width, count, gap }) / width;
  const transformOrigin = `${oX}px ${oY + height}px`;
  const x = width * scaleX * index + gap * index;

  return {
    scaleY,
    scaleX,
    transformOrigin,
    x,
  };
}

function getColumnWidth({ count, width, gap }) {
  return (width - (count - 1) * gap) / count;
}

function clamp(n, min, max) {
  return Math.min(max, Math.max(min, n));
}

function normalize(n, min, max) {
  return (n - min) / (max - min);
}

function map(n, min1, max1, min2, max2) {
  let val = clamp(n, min1, max1);
  return normalize(val, min1, max1) * (max2 - min2) + min2;
}

function Boy(props) {
  return (
    <Group sx={{ transformOrigin: "90% 895px" }} {...props}>
      <g>
        <path
          d="M870.9,412.2a13.7,13.7,0,0,0,2.9-.2c5.7-.7,12-3.7,15.5-8.3s2-11.2,1.4-16.7c-.7-7.7-1.4-15.7-2.8-23.4-.9-4.4-3.2-7.9-5.2-11.8s-4.5-8.5-7.9-11.8c-6-5.9-16.4-8.1-24.2-4.3a24.5,24.5,0,0,0-8.8,7.1c-9.2,12.6-2.5,29.9,1.1,43.2.9,3.5,1.2,6.3,3.1,9.4s2.9,4.8,4.3,7.2C854.1,409,863.3,412.3,870.9,412.2Z"
          fill="#99552e"
        />
        <path
          d="M850.6,335.7a24.5,24.5,0,0,0-8.8,7.1,32.1,32.1,0,0,0-1.9,3.1,48.9,48.9,0,0,0,24.5-11.5A21.7,21.7,0,0,0,850.6,335.7Z"
          fill="#e18d58"
        />
        <path
          d="M852.7,398.3a26.3,26.3,0,0,1,4.9-.5,51.4,51.4,0,0,0,17.7-4.1,46.6,46.6,0,0,0,8.5-4.6c2.1-1.4,4.2-4.2,6.8-4.6.9-.2,1.3,7.3,1.3,7.9.3,4.2.7,8.3-1.9,11.9-5.5,7.4-15.3,10-24.2,9.8-5.4-.2-9.6-5-13.7-8.2s-5.8-5.9-.1-7.5Z"
          fill="#35353e"
        />
        <path
          d="M829.8,404.1a5.2,5.2,0,0,0-.8,2,23.1,23.1,0,0,1-2.6,7.5c-3.6,6-7.6,11.8-10.8,17.9-2.3,4.2-4.2,8.5-4.6,13.2s.5,10.3.9,15.3c.5,7.1,1.2,14.3,2,21.4.4,2.9-.1,7.9,1.5,10.6s6,1.4,8.3,1.2,4.7.2,6.1-1.2,1.2-4,1.1-5.8c-.7-10.3,2.3-20.3,4.1-30.4.2-.8-.1-1.6.2-2.3s4.6-4.1,6.4-5.6c5.4-4.9,11.9-11.1,13.5-17.9a58,58,0,0,0,1.7-11.6c.2-2.9-.2-5.9.2-8.7.1-.9,2.7-7.2,1.6-7.5-6.2-1.8-12.9,0-19.2.4C835.4,402.8,831.6,401.4,829.8,404.1Z"
          fill="#2f8e93"
        />
        <path
          d="M858.6,402.2c-6.2-1.8-12.9,0-19.2.4-4,.2-7.8-1.2-9.6,1.5a5.2,5.2,0,0,0-.8,2,23.1,23.1,0,0,1-2.6,7.5c-3.6,6-7.6,11.8-10.8,17.9-2.3,4.2-4.2,8.5-4.6,13.2s.5,10.3.9,15.3c.5,7.1,1.2,14.3,2,21.4.4,2.9-.1,7.9,1.5,10.6s5.8,1.4,8.2,1.2c3.2-12.9,1.3-26.5,7.9-38.1,2.2-3.9,6.4-6.1,10.8-7.9,5.3-4.7,11.2-10.7,12.8-17.2a58,58,0,0,0,1.7-11.6c.2-2.9-.2-5.9.2-8.7C857.1,408.8,859.7,402.5,858.6,402.2Z"
          fill="#4db6c1"
        />
        <path
          d="M841.1,419.6a85.3,85.3,0,0,1,1.3,15.9c.1,7.6-3.6,15.6-1.4,23.1,1.3,4.1,4.3,7.7,6.4,11.5a97.2,97.2,0,0,0,9.8,14.5c1.9,2.2,3.5,4.7,5.9,6.5s6.6,3.3,9.8,4.8a4.8,4.8,0,0,0,2.1.7c4.1,0,1.3-8.9.9-10.8-1.7-8.4-5.3-16.4-7.8-24.7-.4-1.4-1.2-2.4-.9-3.8.6-4.2,3-8.4,4.4-12.5a57.3,57.3,0,0,0,3.4-13.4,57.9,57.9,0,0,0,.1-13.8c-.4-4-.5-9.2-3.9-12.3s-9.9-2.5-13.5-2.9c-5.4-.6-16.9-2.4-18.7,4-.8,2.6.1,5.2.8,7.7S840.7,417.8,841.1,419.6Z"
          fill="#2f8e93"
        />
        <path
          d="M857.1,463.4c9.6-19.1,10.3-40.3,6.7-60.4l-6.1-.6c-5.4-.6-16.9-2.4-18.7,4-.8,2.6.1,5.2.8,7.7s.9,3.7,1.3,5.5a85.3,85.3,0,0,1,1.3,15.9c.1,7.6-3.6,15.6-1.4,23.1,1.3,4.1,4.3,7.7,6.4,11.5a97.2,97.2,0,0,0,9.8,14.5l1.2,1.4a24.5,24.5,0,0,1,1.5-4.3C861.8,475.9,854.2,470.1,857.1,463.4Z"
          fill="#4db6c1"
        />
        <path
          d="M825.3,371h0c-.7-1.9-3.6-.5-4.8.3a4.1,4.1,0,0,0-2.2,3.2c-.6,3.5-.2,7.3-.6,10.8a90.9,90.9,0,0,1-1.8,10.2c-.7,2.7-.8,5.5,1,7.9s4.1,3.3,6.4,3,2.1-1.3,2.9-2.4c3.2-4.5,3.3-10.3,3.1-15.5A47.5,47.5,0,0,0,825.3,371Z"
          fill="#a4c4d5"
        />
        <path
          d="M852.9,337.6c-5.3.6-10.4,4.2-14.4,7.4-6.2,4.8-9.5,12.9-11.4,20.1s-1.9,15.6-1.5,23.4c.2,3.6.1,7.3.6,10.9s1,7.6-.3,11.2a5.3,5.3,0,0,0-.4,1.7c.3,2.5,5.9,3.9,7.8,4.4a56.1,56.1,0,0,0,22.7,1.9,72.3,72.3,0,0,0,11.1-2.1c2.7-.7,7.2-1.2,8.5-4.1s.6-5.9.2-8.5-1.2-7.1-1.5-10.8c-.8-8.8-1.5-17.7-2.5-26.5s-1.5-24.5-13.4-28.4A13.1,13.1,0,0,0,852.9,337.6Z"
          fill="#6e8390"
        />
        <path
          d="M852.3,368c-3.8-2.8-14.2-1-18-7.5a14.6,14.6,0,0,1-1.8-8.7,52.4,52.4,0,0,0-5.4,13.3c-2.1,7.6-1.9,15.6-1.5,23.4.2,3.6.1,7.3.6,10.9s1,7.6-.3,11.2a5.3,5.3,0,0,0-.4,1.7c.3,2.5,5.9,3.9,7.8,4.4a56.1,56.1,0,0,0,22.7,1.9,47.2,47.2,0,0,0,6.4-1C868.4,399.8,867.6,380.1,852.3,368Z"
          fill="#a4c4d5"
        />
        <path
          d="M820.2,363c.1-1.9-1.4-3.6-.2-5.5a9.8,9.8,0,0,1,2.4-2.1l10.9-7.4,5.3-3.6a9.8,9.8,0,0,1,2.2-1.3,14.3,14.3,0,0,0,2.2-.4c-2.4,4.3-7.1,6.9-10.7,9.9s-7.4,7.6-11.5,10.9C820.8,363.4,820.2,362.9,820.2,363Z"
          fill="#35353e"
        />
        <path
          d="M855.4,338.7c3-1.4,9,1.8,11.6,3.3,6.4,3.8,8.7,10.2,10.7,16.8,3.3,11.2,3.4,23.4,2.2,35-.4,3.5-3,4.9-5.7,2.3s-4-5.8-5.3-8.8a17.7,17.7,0,0,1-1.5-3.8,64.9,64.9,0,0,1-1.7-11.6,28.3,28.3,0,0,0-2.8-9.4c-2.2-4.9-5.7-9.1-7.8-14C853.8,345.7,851.4,340.7,855.4,338.7Z"
          fill="#6e8390"
        />
        <path
          d="M874.2,360.5c-1.7-7.2-9.6-7.5-16-6.2a82.8,82.8,0,0,1,4.7,8.2,28.3,28.3,0,0,1,2.8,9.4,64.9,64.9,0,0,0,1.7,11.6,17.7,17.7,0,0,0,1.5,3.8,32.7,32.7,0,0,0,4.2,7.5C877.3,384.5,877.4,372.7,874.2,360.5Z"
          fill="#a4c4d5"
        />
        <path
          d="M852,363.5c-.4,1.1.3,2.5.6,3.4,2.6,7.4,4.6,15.3,8.4,22.2a28.9,28.9,0,0,0,7.4,8.9,16.4,16.4,0,0,0,4.7,3c2,.6,3.1-.5,1.9-2.5a9.1,9.1,0,0,0-2.4-2.4,38.8,38.8,0,0,1-10-12.1,39.9,39.9,0,0,1-3.5-10.4c-.6-3.2-.5-7-2.7-9.6a3.8,3.8,0,0,0-1.8-1.3,2.3,2.3,0,0,0-2.1.2Z"
          fill="#35353e"
        />
        <path
          d="M864.6,364.3c-.1,0-.1-.1-.1-.2-.9-4.3-5.7-.6-7.4.7s-2.6,2.7-1.7,5.5,2.1,6.2,3.1,9.4l4.2,12.7c1.2,3.5,2,8.3,5.6,10.2a11.2,11.2,0,0,0,8.8-.1c2.7-1.4,2.2-3.5,2.8-6.2s1.2-4,1.1-5.7-1.6-5.3-2.5-7.4C875.7,376,868.8,370.7,864.6,364.3Z"
          fill="#6e8390"
        />
        <path
          d="M860,362.9a16.2,16.2,0,0,0-2.9,1.9c-2.4,1.8-2.6,2.7-1.7,5.5s2.1,6.2,3.1,9.4l4.2,12.7c1.2,3.5,2,8.3,5.6,10.2a10.7,10.7,0,0,0,7.1.5,11.1,11.1,0,0,0,.7-1.6C879.7,386.3,868.4,374.5,860,362.9Z"
          fill="#a4c4d5"
        />
        <path
          d="M851.4,357.4c-.8-2.2.4-4.7,1.1-6.8s2.5-5.6,3.6-8.4,2.4-7.1,6.6-7.1c1.6,0,4.2-.4,5.2,1.1s-.7,1.9-1.7,3a15.6,15.6,0,0,0-3.3,5.9,66.7,66.7,0,0,1-3.5,7.1c-.9,1.9-1.9,4.8-3.6,5.8S851.5,359.3,851.4,357.4Z"
          fill="#35353e"
        />
        <path
          d="M867.5,336.5c.1-1.2-1.4-2.5-2-3.5s-1.6-2.7-3.6-3.3-6.2-1.4-8.5-.5-5.2,5.1-4.6,7.5c1,4,3.7-3.3,4.4-4.3s5.5-2.8,7.3-1.2,1,5,2.8,6.3S867.4,338.5,867.5,336.5Z"
          fill="#35353e"
        />
        <path
          d="M850.9,338.9a10.9,10.9,0,0,1-.5-1.7,41.8,41.8,0,0,0-5.1-10.4c-.7-1.1-2.1-3.7-3.4-4.1s-2.8,1.4-3.7,2.2-2.3,2.3-3.4,3.5a30.7,30.7,0,0,0-3.4,3.8c-2.4,3.3-2.6,7.2-1.4,11,.6,1.9,2.1,2.7,3.2,4.2s1.3,4.8,3.3,5.7c3.5,1.5,7.3.1,10.1-2.2a14.3,14.3,0,0,0,4.6-6C852,343,851.5,340.9,850.9,338.9Z"
          fill="#ba7d5d"
        />
        <path
          d="M820.9,374.8a19,19,0,0,1-4-1.4c-.9-.5-2.7-1.7-2.9-2.8s.7-2.6,1-3.4l2.1-5.6c1.1-2.5,1.8-2.4,4.2-2s2.7-.3,4.5-1.1a2.4,2.4,0,0,1,1.4-.3c.9,0,1.3,1.2,1.4,2a5.5,5.5,0,0,1-.7,2.4c-.1.1-.3.2-.3.4a9.1,9.1,0,0,1-.8,8.6l-.8,1C824.7,374,822.8,375.2,820.9,374.8Z"
          fill="#c18a6e"
        />
        <path
          d="M841.8,310.6a29.2,29.2,0,0,0-3.2-3.8c-4-4.1-8.6-4.9-14.3-3.8-10.5,2.1-17.9,10.1-17,20.9.6,7.2,5.6,14.1,11.1,18.7s7.8,7.3,13.9,6.9c14.1-.9,17-18.8,14.1-29.4A33.4,33.4,0,0,0,841.8,310.6Z"
          fill="#ba7d5d"
        />
        <path
          d="M828.6,334.5c-8.6-4.3-13.4-10-21.3-13.8a17.1,17.1,0,0,0,0,3.2c.6,7.2,5.6,14.1,11.1,18.7s7.8,7.3,13.9,6.9a16.1,16.1,0,0,0,3.3-.6C835.5,342.9,833.8,337.3,828.6,334.5Z"
          fill="#c18a6e"
        />
        <path
          d="M855.6,353.4a3.1,3.1,0,0,1-1,.5,9.7,9.7,0,0,1-1.7,0,3.8,3.8,0,0,0-2.6.6c-1,.7-2.2,1.6-2.3,2.8s.9,2.7,1.4,4.1a24.5,24.5,0,0,1,.8,2.9c.1.8,0,1.8.7,2.4s3.1.3,4.3,1.2,1.5,1.9,2.7,2.3,5.5-1.2,5.8-3.5a12.2,12.2,0,0,0-.4-4.6c-.5-2.2-.2-4.4-.3-6.7s-1.2-2.4-2-3.3a2.1,2.1,0,0,0-2.1-.8C857.5,351.6,856.7,352.7,855.6,353.4Z"
          fill="#ba7d5d"
        />
        <path
          d="M859,354a2.5,2.5,0,0,0-2.4-1.4l-1,.8a3.1,3.1,0,0,1-1,.5,9.7,9.7,0,0,1-1.7,0,3.8,3.8,0,0,0-2.6.6c-1,.7-2.2,1.6-2.3,2.8s.9,2.7,1.4,4.1a24.5,24.5,0,0,1,.8,2.9c.1.8,0,1.8.7,2.4s3.1.3,4.3,1.2,1.2,1.4,1.9,1.9C861.8,367,862.5,359.5,859,354Z"
          fill="#c18a6e"
        />
        <path
          d="M837.8,320.1a2.1,2.1,0,0,1,1.9-1.2,1.9,1.9,0,0,1,1.4.9,23.9,23.9,0,0,1,1.3,2.8c.5,1,2.6,4.7,4.1,4.1s.7-2.7.7-3.7a25.4,25.4,0,0,0-2.3-7.4,11,11,0,0,0-3.6-5.4c-3.4-2.5-6.4-.6-9.5,1.2a21.1,21.1,0,0,0-7.3,6.3c-1,1.3-1.1,1.7-.5,3.3s1.1,2.3,2.6,2.6a19.7,19.7,0,0,0,3.5.4c1.6.1,2.8,0,4.2.9s2.1.7,2.8-.4.2-2.7.6-4.1Z"
          fill="#35353e"
        />
        <path
          d="M810,332.4c1.4.8,2.7.5,5.2-.4a75.1,75.1,0,0,0,16.7-8.5c2.5-1.7,5.3-3.1,7.6-5s5.6-4.5,3.3-7.9c-3.6-5.3-8.5-9.4-15.1-9.9s-12.9,1.4-17.1,6c-2.6,3-6.1,6-6.5,10S805,329.5,810,332.4Z"
          fill="#20496d"
        />
        <path
          d="M833.3,302.1a14.9,14.9,0,0,0-5.6-1.4c-6.2-.6-12.9,1.4-17.1,6-2.6,3-6.1,6-6.5,10s.9,12.8,5.9,15.7c1.4.8,2.7.5,5.2-.4a75.1,75.1,0,0,0,16.7-8.5l3.1-1.9A36.7,36.7,0,0,0,833.3,302.1Z"
          fill="#2559a7"
        />
        <path
          d="M802.9,342c12.4,1.9,22.4-10,29.9-17.5,1.7-1.8,5.3-4.8,5.3-7.3-5.5,4.4-12.5,7.7-18.9,10.9a14.5,14.5,0,0,1-6.8,1.6c-1.8-.1-3.2-1-4.9-1.2-.7,2-9,12.6-4.8,13.5Z"
          fill="#2559a7"
        />
        <path
          d="M838.7,324.1a16.3,16.3,0,0,0-1.4-1.8,6.2,6.2,0,0,1-1.8-3,3.4,3.4,0,0,1,1.3-3.4,2.9,2.9,0,0,1,2.2-.7,3.9,3.9,0,0,1,2.3,1.7,8.9,8.9,0,0,1,1.9,5.4c0,1.3-.9,3.3-2.5,3.2A3.7,3.7,0,0,1,838.7,324.1Z"
          fill="#ba7d5d"
        />
        <path
          d="M812.7,479.1c.1-.4,1.9-.5,2.3-.5l4.4-.3c2.2-.1,4.3-.1,6.5,0s3.9.3,5.1,1.7.3.4.2.8a63.3,63.3,0,0,0,.3,9.7c0,.7.1,1.6-.6,2a10.5,10.5,0,0,1-3.5.8l-3.9.4-4.3.2a18.8,18.8,0,0,1-3.4-.2c-1.1-.3-1.2-1-1.4-1.9s-.6-2.3-.8-3.4A58.6,58.6,0,0,1,812.7,479.1Z"
          fill="#35353e"
        />
        <path
          d="M858.4,487.4c-.1-.4-.3-.8-.1-1s1.6.5,2,.7a11,11,0,0,0,3.4,1c4,.4,8.8-1.5,10.3-5.5.2-.4.1-1.4.6-1.7s.8,1.2.9,1.5.5,2.1.8,3.2a30.1,30.1,0,0,1,1,6,16,16,0,0,1,0,3.1,3.1,3.1,0,0,1-.7,2c-.3.2-.9.1-1.2.1a19.8,19.8,0,0,1-8.9-2.5,30.1,30.1,0,0,1-4.2-2.7,16.7,16.7,0,0,1-3.3-3.2Z"
          fill="#35353e"
        />
      </g>
      <path
        d="M869.8,576.7l10.5-63-4.4-14.3-28.4-9.5-19,6.2s-19-1.7-20.7-1.7l-48,1.5-11.1,49.3,9,20.1-3.3,9.7,1.5,139.2-7.5,15.5,1.9,51.9-1.7,111.9L777.1,870l25-136.8,21.2-4.3,19.1-124.8,10.3,88.2,17.4-23,21.3-64.8ZM777.1,815.3l-11.7,57.1,4.2-71.2,1.7-65.5-2.9-20.5,13.4,18.2Zm26.1-178.8-6.5-54-23.1-16.9,20.1-7.2,11.1-18,12.3,23.8,1.5,21.2-13.2,75.1ZM843,587.9l-4.5-53.7L867,510.3Z"
        fill="#d6dbb9"
      />
      <path
        d="M749.8,895.7c-1.8-3.2-.6-9.6-.7-13.1-.2-6-.3-11.9-.5-17.9-.6-21.2-1.2-42.2.4-63.4s4-42.8.3-63.9a25.4,25.4,0,0,1-.7-4.7,13.3,13.3,0,0,1,.1-4,49.3,49.3,0,0,1,4.9-11.6c4.2-7.8,1-127.3-.7-137.4-.8-4.7,1.4-6.9,3.2-10.7s2-5-.6-10.2-8.4-14.3-5.6-20.9a15.4,15.4,0,0,0,1.4-5.2c.7-11.8,5.5-23.3,5.8-35.1,0-.6-.6-2.6-.1-3.1s1.2-.2,1.6-.2l4.8-.3c4.6-.3,33.3,2.1,37.8,1.4,1.3-.2,2.4-.8,3.7-1.1l2.8-.4c5.1-.2,10.4-.1,15.5.1,10.2.4,17.9-4.8,27.7-4.5s18.8,6.4,28.1,9.5c5.4,1.8,11.3,1.4,16.8,3.1,9.2,3,19.2,9.5,29.5,8.9,3.6-.3,8.3,1.5,12.2,1.9"
        fill="none"
        stroke="#acb56e"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g>
        <path
          d="M944,873.5c8.8,3,23.5,1.3,30.7-4.9a22.5,22.5,0,0,0,7.6-18c10.4-6.4,19.6-15.4,23.6-26.9s1.5-25.7-8-33.3c6.7-6.3,8.6-15.9,10.2-24.8,1.1-6.5,2.3-13.2.5-19.5a31.3,31.3,0,0,0-6.7-11.4c-8.7-9.8-22.1-15.8-35.2-14.4a13.5,13.5,0,0,0-25.2-.8c-.2-10.2-2.8-20.5-8.4-29-8-12-21.5-18.8-33.8-7.9-6.5,5.8-9.8,14.3-11.9,22.6a113.2,113.2,0,0,0-3.4,27.2c-2-5.5-9.6-7.4-14.6-4.6s-7.6,9-7.5,14.8c-9.4.9-19.8,2.7-27,9.2a56.8,56.8,0,0,0-14.7,20.9,15.9,15.9,0,0,0-1.5,7.7,16.4,16.4,0,0,0,3,6.9,61.5,61.5,0,0,0,17.6,17.6,20,20,0,0,0-4.4,33.8c1.2,1,2.6,1.9,2.8,3.4a6.5,6.5,0,0,1-.3,2.8c-.9,4.8,1.7,9.7,5.5,12.7s8.5,4.5,13.2,5.8a216.8,216.8,0,0,0,29.9,6"
          fill="none"
          stroke="#4db6c1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M894.3,893.1A133.6,133.6,0,0,0,879,828.7"
          fill="none"
          stroke="#acb56e"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M913.7,893.7l.2-118"
          fill="none"
          stroke="#acb56e"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M922.8,892.5c2.1-32.7,6-66.2,19.5-96.4"
          fill="none"
          stroke="#acb56e"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M932.2,890.6A79.3,79.3,0,0,1,954.1,838"
          fill="none"
          stroke="#acb56e"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M885.3,799.3c16.1,29.1,18.5,60.7,19.3,93.2"
          fill="none"
          stroke="#acb56e"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Group>
  );
}

function Mother(props) {
  return (
    <Group sx={{ transformOrigin: "10% 895px" }} {...props}>
      <polygon
        points="146 904.6 249.5 910.6 432.9 905.4 415.3 903.5 330.1 902.8 234.1 903.1 207.9 905 300 906.3 280.8 907.8 228.1 907.4 178.9 904.1 146 904.6"
        fill="#acb56e"
      />
      <path
        d="M81,877.3A53.6,53.6,0,0,0,60.6,855c4.4-1.1,8.9,1.5,12.1,4.6s5.8,7.1,9.6,9.6a129.9,129.9,0,0,0-14.1-51.7l3.9,4.7a96.2,96.2,0,0,1,19.5,44.7,205.9,205.9,0,0,1,4-23.5c-.2,7.7-.1,15.4.4,23.2a52.4,52.4,0,0,1,8.3-12A239.4,239.4,0,0,0,96,883.2"
        fill="none"
        stroke="#4db6c1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.1,886.6a40,40,0,0,0-29.8-5"
        fill="none"
        stroke="#acb56e"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g>
        <path
          d="M218,528.2c-1.5.5-2.9-.5-2.1-1.8a13.9,13.9,0,0,1,1.5-1.9c.6-.8.8-1.7,1.4-2.5s1.6-2.1,2.4-3a8,8,0,0,1,1.3-1.3,6.1,6.1,0,0,1,1.4-.7l3.7-1.3h.3c.1,0,.1.2.2.3.5,2.3-2.9,2.6-4.2,3.7a6.5,6.5,0,0,0-1.8,3.1l-1.5,2.9a10.5,10.5,0,0,1-.8,1.1A3.6,3.6,0,0,1,218,528.2Z"
          fill="#74514b"
        />
        <path
          d="M216.5,528.4a2,2,0,0,1-1.7.3,2,2,0,0,1-.9-1.9,3.7,3.7,0,0,1,0-1.8,7.8,7.8,0,0,1,.5-1.4,27.5,27.5,0,0,1,1.5-3.6,10,10,0,0,1,.8-1.4l.9-1.6c.6-.8,1.5-1.5,2.1-2.3l.8-1.3a17.9,17.9,0,0,1,1.4-1.3l1.9-1.4a11.2,11.2,0,0,1,2-1.8c.3-.1.6-.3.9-.2a.7.7,0,0,1,.3.6,3.3,3.3,0,0,1-.8,3,25.2,25.2,0,0,1-2.5,2.1,1.8,1.8,0,0,0-.7.5l-.8,1.2-.9,1.3a4.1,4.1,0,0,1-.5.9,15.6,15.6,0,0,0-2,5.6,11.1,11.1,0,0,1-.5,2.5A4.9,4.9,0,0,1,216.5,528.4Z"
          fill="#74514b"
        />
        <path
          d="M214.2,529.8c-2.7.6-2.5-4.8-2.2-6.2s.5-1.5.7-2.3.3-1.3.5-2a36.1,36.1,0,0,1,1.7-4.6l2.1-4.1,4-7.3c.1-.3.2-.5.5-.6s1.3,3.7,1,4.4l-.4,1c-.6,1.2-1.2,2.4-1.9,3.6a20.4,20.4,0,0,0-2.2,5.8,12.9,12.9,0,0,0-.4,1.9c-.4,2-.3,4-.5,6S216.5,529.3,214.2,529.8Z"
          fill="#74514b"
        />
        <path
          d="M218.3,521.6l.5.4a4.3,4.3,0,0,1,2,1.5,7.1,7.1,0,0,1,.2,2.9,49.1,49.1,0,0,1-.6,6.6,8,8,0,0,1-.3,1.5,7.5,7.5,0,0,1-1.9,2.4,3.5,3.5,0,0,0-1,1.1,6.1,6.1,0,0,0-.3,1.3c-.3,6.8-1.2,13.7-2.1,20.5a16.7,16.7,0,0,1-.6,2.9c-1,2.3-3.3,2.6-5.5,2.2a8.6,8.6,0,0,1-2.8-1.1l-1.3-.7a4.5,4.5,0,0,1-1.4-1,2.1,2.1,0,0,1-.6-1,16.3,16.3,0,0,1,.1-4.4c.1-1.5.2-3,.4-4.5s.8-6,1.3-9c1.1-6,2.4-11.9,3.1-17.9.2-1.4.3-2.7.5-4,.6-3.2,1.9-6.2,2.8-9.3a14.2,14.2,0,0,1,.8-1.8,54.2,54.2,0,0,0,2.6-8.7,1.9,1.9,0,0,1,.5-1,1.1,1.1,0,0,1,1.1,0,2,2,0,0,1,.7.9,5.8,5.8,0,0,1,.4,4.1,26.2,26.2,0,0,1-1.1,4c-.8,3-1.8,8,.5,10.6A7.5,7.5,0,0,0,218.3,521.6Z"
          fill="#74514b"
        />
        <path
          d="M214.1,535.7c1.5-10.5-4.2-21.5,2.3-32.4a6.5,6.5,0,0,0,.4-1.3l-.3-.6a2,2,0,0,0-.7-.9,1.1,1.1,0,0,0-1.1,0,1.9,1.9,0,0,0-.5,1,54.2,54.2,0,0,1-2.6,8.7,14.2,14.2,0,0,0-.8,1.8c-.9,3.1-2.2,6.1-2.8,9.3-.2,1.3-.3,2.6-.5,4-.7,6-2,11.9-3.1,17.9-.5,3-1,6-1.3,9s-.3,3-.4,4.5C210.8,552.8,212.8,543.3,214.1,535.7Z"
          fill="#82625d"
        />
        <path
          d="M211.6,537.5a7.5,7.5,0,0,1-1.7-3.3,26.9,26.9,0,0,1-.3-4c-.2-1.6-1.1-2.6-1.5-4.1s-.9-3.8-1.3-5.7a19.1,19.1,0,0,0-1.9-5.2,3.4,3.4,0,0,1-.5-2.4,2.7,2.7,0,0,1,2.3.3,6.8,6.8,0,0,1,3.2,3.3,8.3,8.3,0,0,1,.7,2.7c.4,2.5,2.9,3.9,4.7,5.4a11.4,11.4,0,0,1,3,3.2,7.2,7.2,0,0,1,.7,4.8,9.7,9.7,0,0,1-4.8,6.8l-.6.2c-.2,0-.4-.2-.6-.4Z"
          fill="#82625d"
        />
        <path
          d="M218.3,527.7a11.3,11.3,0,0,0-2.3-2.6c1.2,4.9.5,10.2-3.5,13.4l.5.6c.2.2.4.4.6.4l.6-.2a9.7,9.7,0,0,0,4.8-6.8A7.2,7.2,0,0,0,218.3,527.7Z"
          fill="#74514b"
        />
        <path
          d="M159.4,639.4a9.6,9.6,0,0,1,1.4-9.9c1.8-2.1,3.2-4.5,5-6.7A171.6,171.6,0,0,0,197,565.2a163.2,163.2,0,0,0,4.6-18.3c.4-2,.5-4.6,1.7-6.5a3.7,3.7,0,0,1,2-1.2c3.8-1.1,7.9-.1,11.4,1.8a.5.5,0,0,1,.4.3c.1.1.1.4.1.6.2,7.9-1.1,16.2-2,24.1a167.3,167.3,0,0,1-4.8,25.4c-3.1,11.1-7.8,21.7-12.3,32.2-2.3,5.3-4.6,10.7-6.6,16.1s-2.6,10.7-5.8,14.6a9.9,9.9,0,0,1-2.9,2.5c-2.7,1.3-6,.8-8.6-.5s-5.8-4.6-8.4-7A26.8,26.8,0,0,1,159.4,639.4Z"
          fill="#99552e"
        />
        <path
          d="M214,539.8a13.7,13.7,0,0,0-8.7-.6,3.7,3.7,0,0,0-2,1.2c-1.2,1.9-1.3,4.5-1.7,6.5a163.2,163.2,0,0,1-4.6,18.3,171.6,171.6,0,0,1-31.2,57.6c-1.8,2.2-3.2,4.6-5,6.7a9.6,9.6,0,0,0-1.4,9.9l.3.9C189.8,614.3,212.1,579.8,214,539.8Z"
          fill="#e18d58"
        />
        <path
          d="M159.2,618.2a29.7,29.7,0,0,1,.7,4.3,3.6,3.6,0,0,0,.9,2.2c1.5,1.8,4.7,1.3,6.6,1.1l7.2-.7c3.7-.4,4.4-4.1,4.3-7.5a66.4,66.4,0,0,0-.5-7.7c-.1-1.3-.3-2.7-.5-4a8.5,8.5,0,0,0-.9-3.2c-1.3-1.9-4.3-2.2-6.2-2.6a44.1,44.1,0,0,0-7.2-.8c-2.7-.1-5.4-.3-8.1-.3s-1.2,2.8-.6,4.3A79.1,79.1,0,0,1,159.2,618.2Z"
          fill="#74514b"
        />
        <path
          d="M164.8,609.1c-1.1-3.8-4.6-6.5-7.1-10h-2.2c-2.5,0-1.2,2.8-.6,4.3a83.1,83.1,0,0,1,4.3,14.5C163.2,616.1,166.2,612.4,164.8,609.1Z"
          fill="#82625d"
        />
        <path
          d="M160.3,604.6c5.5,2.2,11.6,3.4,17.1,1.4a19.5,19.5,0,0,0,7.8-4.7c2.5-2.6,3.3-5.4,3.7-8.8a34.4,34.4,0,0,0-12.1-30.7c-3.3-2.8-7.6-5.2-12-4.7-6.7.6-13.1,2.2-17.8,7.2a24.6,24.6,0,0,0-6.7,17.4c.3,9.2,5.6,15.3,13.4,19.7A67.8,67.8,0,0,0,160.3,604.6Z"
          fill="#74514b"
        />
        <path
          d="M173.3,599.1c9.3-12,7.7-29-1.2-40.5a13.4,13.4,0,0,0-7.3-1.5c-6.7.6-13.1,2.2-17.8,7.2a24.6,24.6,0,0,0-6.7,17.4c.3,9.2,5.6,15.3,13.4,19.7a67.8,67.8,0,0,0,6.6,3.2l2.2.8C166.7,604.4,170.9,602.7,173.3,599.1Z"
          fill="#82625d"
        />
        <path
          d="M144.3,562.6a28.8,28.8,0,0,1,17.1-6.4c2.1-.1,11.2-.4,10.9,2.8a4.4,4.4,0,0,1-1.1,2.4l-4.1,5.4A5.1,5.1,0,0,0,166,569a7.8,7.8,0,0,0,.2,1.4,14.7,14.7,0,0,1-.4,8.3c-.4,1.3-1.1,2.7-.7,4a7.4,7.4,0,0,1,.4,1.4,1.6,1.6,0,0,1-1,1.6c-1.9.9-3.3-.7-5-.6s-2.4,1.7-2.4,3.2a15.1,15.1,0,0,0,1.3,4.1,9.2,9.2,0,0,1-.6,6.9,10.4,10.4,0,0,1-1.2,1.7c-1.9,2.5-5,2.1-7.9,2.1-4.8-.1-10.6-.5-13.7-4.6s-2.9-8.2-2.1-12.3C134.3,577.8,137.5,568.2,144.3,562.6Z"
          fill="#35353e"
        />
        <path
          d="M141.6,591.8a25.5,25.5,0,0,1,.7,13.5c-.4,2.3-1.3,4.7-3.2,6.1-5.8,4.4-17.8,2.9-23-2s-4.9-11-3.6-16.6,6.1-14.6,13.6-13.7a20.4,20.4,0,0,1,8.1,3.3A20.7,20.7,0,0,1,141.6,591.8Z"
          fill="#35353e"
        />
        <path
          d="M148.2,574.6l-2.8-4.4-2.2-3c-.4-.6-1.9-1.6-1.8-2.4l.3-.5a21.6,21.6,0,0,1,4.2-3.8c.9-.6,2.1-1.1,2.8-.1,3,4.2,5,9.5,6.8,14.3,1,2.8,1.9,5.7,2.7,8.5s.8,2.9,1.1,4.4,1,3,.4,4a1.2,1.2,0,0,1-.9.5,1.6,1.6,0,0,1-1-.2,4.9,4.9,0,0,1-2.3-2.2c-2-4.5-3.8-8.9-6.2-13.2Z"
          fill="#fad255"
        />
        <path
          d="M163.5,592.6l.6-.2a1,1,0,0,0,.5-.4c.9-1.2.6-2.8.1-4.2s-2.4-5.1-5.5-5.3-3.2,2.2-3.4,4a5.3,5.3,0,0,0,3.9,5.8A11.7,11.7,0,0,0,163.5,592.6Z"
          fill="#82625d"
        />
        <path
          d="M183.6,719.8a22.8,22.8,0,0,1,2.9-3.3c4.8-4.6,10.9-4,13.9,2.3,1.6,3.4,2,7.2,2.4,11a433.4,433.4,0,0,0,7.2,46.8c3.2,14.9,1.7,30.9,1.1,46s-.7,29.4-1.7,44.1c-.1,2.7-.8,5.9-3.5,6.8-7.6,2.5-9.7-11.5-11-16.1-3.8-13.9-6.3-28-5.6-42.4.3-6,.6-11.9-2.9-17.1s-7.2-10.5-9.5-16.7a62.4,62.4,0,0,1-3-32.6C175.5,738.6,177.5,727.8,183.6,719.8Z"
          fill="#6e8390"
        />
        <path
          d="M155,705.7a12.3,12.3,0,0,0-5.4,4.4c-4.6,7.4-5.6,16.6-4,25.3,1.3,6.9,3.9,13,6.5,19.4s-.2,11.7.1,17.6c.3,8.4,2.7,16.8,2.8,25.2,0,3.6-1.3,7.2-2.5,10.6-6.5,19.1-7.2,38.8-6.8,58.7.1,3.8-1.8,15.9,4,16.6a6.3,6.3,0,0,0,5.4-2.7,19.6,19.6,0,0,0,2.6-5.7c6-19.2,11.7-38.5,18.5-57.5,2.7-7.8,5.5-15.6,7.8-23.5,3.5-12.3,4.7-25,8.4-37.2s11.5-21.4,13.3-33.8c.6-4,1.1-8,1.8-12s-1.8-7.1-5.9-7.7a126.7,126.7,0,0,0-28.2-.7C168.1,703.2,160.5,703.3,155,705.7Z"
          fill="#6e8390"
        />
        <path
          d="M180.7,702.3l-7.3.4c-5.3.5-12.9.6-18.4,3a12.3,12.3,0,0,0-5.4,4.4c-4.6,7.4-5.6,16.6-4,25.3,1.3,6.9,3.9,13,6.5,19.4s-.2,11.7.1,17.6c.3,8.4,2.7,16.8,2.8,25.2,0,3.6-1.3,7.2-2.5,10.6-6.5,19.1-7.2,38.8-6.8,58.7.1,3.8-1.8,15.9,4,16.6h1.2c10.2-31.9,17.3-64.1,19.8-97.7C173.3,758.4,178.6,730.4,180.7,702.3Z"
          fill="#a4c4d5"
        />
        <path
          d="M184.2,615.1c4.1,1.9,7.8,3.4,10.7,7,4.8,6.1,7.5,13.5,9.3,21s4,16,1.5,22.5c-3.4,9.1,0,16.1,1.8,25s3.4,31.7-7.5,33.6a63.2,63.2,0,0,1-11.4,1.3c-3.5-.1-6.7-1.1-10-1.5a91.5,91.5,0,0,1-9.1-1.8l-15.2-3.4c-9-2-7.6-8-3.7-14.8,2.9-4.9,4.3-9.8,5.6-15.3.7-2.7,1.6-4.7,1.4-7.5-.3-6.9-4.8-14.8-6.5-21.5-2.5-10.1-5.8-21.2-3.6-31.6s8.6-18.7,19.7-18.3c3.5.1,8-.3,10.8,1.9A33.8,33.8,0,0,0,184.2,615.1Z"
          fill="#99552e"
        />
        <path
          d="M167.2,609.8c-11.1-.4-17.6,8.3-19.7,18.3s1.1,21.5,3.6,31.6c1.7,6.7,6.2,14.6,6.5,21.5.2,2.8-.7,4.8-1.4,7.5-1.3,5.5-2.7,10.4-5.6,15.3-3.9,6.8-5.3,12.8,3.7,14.8l15.2,3.4,6.5,1.4a213.3,213.3,0,0,0,5.7-56.9c-.1-2.1-.4-4.4-.7-6.9-3.3-17.3-5.7-34.7-13.7-50Z"
          fill="#e18d58"
        />
        <path
          d="M140.7,895.6c.1-1.8.3-3.6.5-5.3.4-4.3.6-8.6.6-13,0-2,.1-4.2-.1-6.3s-.6-2.5.9-3.6a7.7,7.7,0,0,1,4-1.1,35.3,35.3,0,0,1,10.2.9c2.4.5,5.8,1.3,6.6,4.1a10.1,10.1,0,0,1,.2,2.5,25.6,25.6,0,0,0,1.3,6.7,12.2,12.2,0,0,0,1.6,3.7c1,1.5,2.7,2.5,4.1,3.7s3.9,4.6,6.3,6.5c.3.2.9,0,1.3.1a12.7,12.7,0,0,1,1.8.6,16.5,16.5,0,0,1,3.1,2c2.2,1.9,3.5,5,2.6,7.7-5.5.5-10.8,0-16.4.1l-10.6.2c-4.6,0-9.1,0-13.7-.3s-3.7-1.8-4.1-5A29.5,29.5,0,0,1,140.7,895.6Z"
          fill="#20496d"
        />
        <path
          d="M156.7,867.1a38,38,0,0,0-10.1-.8,7.7,7.7,0,0,0-4,1.1c-1.5,1.1-1.1,2.2-.9,3.6s.1,4.3.1,6.3c0,4.4-.2,8.7-.6,13-.2,1.7-.4,3.5-.5,5.3a29.5,29.5,0,0,0,.2,4.2c.4,3.2.8,4.8,4.1,5s9.1.3,13.7.3l10.6-.2h2.8C165.8,892,155.7,881.7,156.7,867.1Z"
          fill="#2559a7"
        />
        <path
          d="M197.2,903.2c-.9-.8-1.1-2.2-1.7-4-1.2-3.5-2.1-6.8-1.8-10.4.4-6.4-1.3-12.7-.4-19.2.1-1,.4-2.2,1.3-2.6a3.3,3.3,0,0,1,1.7-.2,24.8,24.8,0,0,0,6.1-.6c2-.2,4.2-.1,6.3-.1s3.7.1,4.7,1.4.8,6.5,1.9,9.3,4.5,6.6,7.4,9l5.4,4.4c1.7,1.5,3.4,2.9,5.2,4.2a21.1,21.1,0,0,1,4.3,3.6c1.2,1.4,3.3,7-.3,7H218.8a133.6,133.6,0,0,1-19-.7A4,4,0,0,1,197.2,903.2Z"
          fill="#20496d"
        />
        <path
          d="M173.8,611.2a28.5,28.5,0,0,0-12.3.8c-6,1.8-11.1,5.6-15.7,9.9a5.5,5.5,0,0,0-1.4,1.9c-.4,1.5.7,2.8,1.2,4.1s.2,1.4.7,1.9a1.8,1.8,0,0,0,2.2,0,8.3,8.3,0,0,0,1.6-2.1,28.6,28.6,0,0,1,8.1-9.2c3-2.3,6.4-4.1,10.1-4.3s8,1.9,11.6,3.7a73.2,73.2,0,0,1,9.3,5.5,20.6,20.6,0,0,1,3.7,2.9c1,1.2,1.8,2.6,3.2,3.4s1.1.5,1.5.1a3.3,3.3,0,0,0,.3-.6,6.4,6.4,0,0,0-1.4-6.3,8.3,8.3,0,0,0-1.8-1.3l-4.1-2.4-1.9-1.1c-2.7-1.5-5.2-3.5-8.2-4.8A27.4,27.4,0,0,0,173.8,611.2Z"
          fill="#494951"
        />
        <path
          d="M194,639.8a7,7,0,0,1-1.3-2.9c-.2-1-1-1.7-1.3-2.6a4.4,4.4,0,0,0-.2-1,10.9,10.9,0,0,0-.8-1.5c-.5-1-.8-2.1-1.2-3.1a3.6,3.6,0,0,1-.2-1,4.5,4.5,0,0,1,1.4-2.6l.5-.2.4.2,1.3,1.1c1.5,1.1,4.2,1.1,4.4-1.3a2.8,2.8,0,0,0-.6-1.8c-.1-.3-.3-.8-.5-.9l-1.8-1.2c-.2-.1-.5-.4-.3-.6h.1a8.2,8.2,0,0,1,5.6-1.2h.4a.8.8,0,0,1,.4.4,4.1,4.1,0,0,0,.7,1.5,4.6,4.6,0,0,0,1.3.8,11.2,11.2,0,0,1,3.1,3.4,5.7,5.7,0,0,0,1.1,1.1l.7.4c.7.7.7,1.8.9,2.8a10,10,0,0,1,.5,3.4,5.1,5.1,0,0,1-.7,1.7,27.5,27.5,0,0,1-2.3,3.3,10.7,10.7,0,0,0-2,3.2,3.8,3.8,0,0,1-.3,1c-.6.7-1.9-.2-2.6-.3l-3.5-.5a5.2,5.2,0,0,1-2.1-.7A5.3,5.3,0,0,1,194,639.8Z"
          fill="#82625d"
        />
        <path
          d="M208.1,679a3.8,3.8,0,0,0,3.2-1.6,9.2,9.2,0,0,0,1-3.6,22.9,22.9,0,0,0,.3-7.4,47.1,47.1,0,0,0-3.4-12.2c-.9-2.1-1.7-4.1-2.7-6.1A16.8,16.8,0,0,1,204,641a1.3,1.3,0,0,0-.1-.6c-.7-3.1-4.3-5.1-7.3-5.3-1.4-.2-4.1.6-4.3,2.3a54.5,54.5,0,0,0,0,7c.2,5.3.8,10.5,1.2,15.8s2.3,11.3,6.8,15.4A11.6,11.6,0,0,0,208.1,679Z"
          fill="#99552e"
        />
        <path
          d="M178,679.2a99.8,99.8,0,0,0,11.7-10.8l2.2-2.6a4.3,4.3,0,0,1,1-.9c1.4-.8,2.2,1,2.1,2.1s-1.3,2.6-2.1,3.8-1.9,3.9-3,5.8a21.7,21.7,0,0,1-3.6,4.4,49.9,49.9,0,0,1-8.9,7c-4.5,2.7-9.4,4.6-14.1,6.8l-15.4,7a7.1,7.1,0,0,1-3.7,1,18.3,18.3,0,0,1,4-4.3,49.8,49.8,0,0,1,4.7-3.3c3.7-2.5,7.7-4.5,11.5-6.8A111.9,111.9,0,0,0,178,679.2Z"
          fill="#494951"
        />
        <path
          d="M156.2,638.2c2.5,4.7,6.8,8.8,10.4,12.5A68.1,68.1,0,0,1,179,668.4a26.8,26.8,0,0,0,6.1,8.8,22.8,22.8,0,0,0,10.1,4.6,51.4,51.4,0,0,0,5.5.6c3.5.3,8.9,1.4,10.9-2.6,4-8.1-1.9-20.5-7.8-26.2-1.6-1.5-3.3-2.8-5-4.2-6.9-6-10.7-13-13.9-21.3-2.7-6.7-8-13.6-15.8-13.8s-13.8,6.5-14.9,14.1A15.9,15.9,0,0,0,156.2,638.2Z"
          fill="#99552e"
        />
        <path
          d="M197.9,676.4c8.1-4.1,2.4-14.6-.8-20.3-6.5-11.3-13-21-17.8-33.2a46,46,0,0,0-3.9-6.8,14.9,14.9,0,0,0-6.3-1.8c-8.1-.2-13.8,6.5-14.9,14.1a15.9,15.9,0,0,0,2,9.8c2.5,4.7,6.8,8.8,10.4,12.5A68.1,68.1,0,0,1,179,668.4a26.8,26.8,0,0,0,6.1,8.8l1.4,1A20.1,20.1,0,0,0,197.9,676.4Z"
          fill="#e18d58"
        />
        <path
          d="M117.8,712.5c2.5,1,5.3,1.6,7.2,2.2,3.8,1.2,9.9,3.6,13.9,2.9s8.1-4.3,9.9-7a17,17,0,0,0,2.2-5.8c6.2-25.1.7-50.4.1-75.8-.1-2.8.8-6.1-2.5-7s-4.8.2-6.9.7a12.5,12.5,0,0,0-5.5,2.2c-1.2.8-2.1,2.1-3.3,2.8a30.7,30.7,0,0,1-4.2,1.7c-4.2,2-6.9,7.4-9.1,11.3a86.4,86.4,0,0,0-11.4,33.7,74.8,74.8,0,0,0,.5,18.3c.9,5,2,13.3,5.2,17.3A9.1,9.1,0,0,0,117.8,712.5Z"
          fill="#20496d"
        />
        <path
          d="M141.7,696.7a233.4,233.4,0,0,0,11-42.2c-.6-8.5-1.4-17-1.6-25.5-.1-2.8.8-6.1-2.5-7s-4.8.2-6.9.7a12.5,12.5,0,0,0-5.5,2.2c-1.2.8-2.1,2.1-3.3,2.8a30.7,30.7,0,0,1-4.2,1.7c-4.2,2-6.9,7.4-9.1,11.3a86.4,86.4,0,0,0-11.4,33.7,74.8,74.8,0,0,0,.5,18.3c.8,4.6,1.9,12.1,4.6,16.4C125.9,709.3,139.1,704.5,141.7,696.7Z"
          fill="#2559a7"
        />
      </g>
    </Group>
  );
}

function Blob(props) {
  return (
    <Group sx={{ transformOrigin: "512px 895px" }} {...props}>
      <path
        d="M162.2,717.5c51.7-34.6,107.5-62.1,161.8-92.8,38.5-21.7,74.5-49.9,113.4-69.9,41.7-21.5,84-42.4,128.5-57.6,59.7-20.5,129.4-30.4,191.5-15.1,67,16.6,111.8,65.6,132.3,129.9,14.7,46.3,28.6,96.2,22.4,144.7-5.4,41.4-21.3,83.8-53,112.3-23,20.6-40.1,18.8-69.8,18.5-41.3-.5-83-.4-124.6-.4l-337.8.3c-34.8,0-69.7,0-104.2.5-25.7.3-54.7,2.2-79-7.8-29-12-48.8-39.5-48.2-71.3C96.2,768.8,127.9,740.4,162.2,717.5Z"
        fill="#cdd4ab"
      />
    </Group>
  );
}

function Treeline(props) {
  return (
    <Group sx={{ transformOrigin: "512px 895px" }} {...props}>
      <path
        d="M10.1,566.1l14.5-18.5a1.8,1.8,0,0,0,.4-.9c-.1-.3-.5-.5-.8-.6l-3.3-1.5,22.2-34.2c.9-1.4,1.9-3,1.5-4.7s-2.6-2.9-3.7-1.6c9.6-16.7,17.5-35.3,28.8-50.7.8,2.8,7.7,30.5,9.1,30.5a102.8,102.8,0,0,0,18.5-39.6l-5.5.7L108,416.9c2.6-4.6,5.3-9.3,9.3-12.8l22,41.5a6.3,6.3,0,0,0-4,1,73.7,73.7,0,0,0,14.3,27.1,105.3,105.3,0,0,0,11.2-35.4c.4-2.9.7-6,2.3-8.5l-5.9-1.1q11.1-19.1,21.6-38.4c6.8,13,14,25.9,21.5,38.5l-4.7,2.3a381.2,381.2,0,0,1,15.1,37.5c12.3-20.1,18.1-44.5,26-66.7l-6.2-2.7a180.3,180.3,0,0,0,13.7-45.8l-4.8-.4a380,380,0,0,1,14.2-47,128.6,128.6,0,0,0,22,45.6L270,355l16.1,48h-5.5L292,445.4a308.6,308.6,0,0,0,28.8-48.2c4.1-8.5,7.8-17.1,11.1-25.9,1.2-3.4,4.4-8.8,4.3-12.5s-1.4-3.7-6-4.6c1.9-3.4,28.7-50,24.8-51.7l-5.4-2.3c5.2-10.4,9.9-21,14.1-31.9,2.7-6.6,5.1-22.8,10.8-26.8,5.2,7.7,8.1,18.4,11.7,27.1l12.1,28.8-5.8.5c2.6,9.5,5.5,19,8.7,28.4a268.9,268.9,0,0,0,22.1-33.8l-4.8-1.5a114.5,114.5,0,0,0,19.8-52.5,318.1,318.1,0,0,0,12.4,38c5.9-12.7,11.6-25.5,17.3-38.3l-7.1-1.3c4.4-9.7,8.8-19.4,13.5-29s10.8-17.7,12.5-26.5c6,13.5,10.6,27.8,15.8,41.7,7.9-7.9,16-16,21.1-26a7.3,7.3,0,0,0-6.4-2.5l24.4-67.9q10.2,26,18.3,52.7l12.3-24.7c15.2,30.3,25.4,62.2,35.3,94.4-2.9,0-6.2-.9-8.8-.2,3.2,9.7,9.9,19.3,14.7,28.5s8.1,21.5,15,29.4c5.1,5.9,5.6-.7,7.9-6.4,4.7-11.7,12.3-22.9,18.3-34l-11.6-2c4.8-17.9,11.4-35,18.6-52.1-2.7.8-5.8.3-8.7.1,11.8-35.1,21.1-62.9,29.5-94.1,6.6,12.7,29.6,83.4,34.3,91.6l-10.6-.4L730.7,268H722a79.7,79.7,0,0,0,9.5,22l23.8-20.9-8.7-2.5q18-32.9,36.9-65.2c-4.4.6-9,1.5-13.3,1.3,2.6.1,14.2-26.7,15.7-30a135.2,135.2,0,0,0,10.3-32.3c-5.3.4-7.6,1.8-13.2,2.9,6.9-21.7,26.8-87.8,37.8-102.7,3.7,6.4,38.1,78.1,45.3,100.1a62.8,62.8,0,0,1-12.1.7l25.5,52.7c-6,1.4-9.3-1.2-15.2-1.6a185.4,185.4,0,0,1,19.9,54.9l34.9-66.6a11.5,11.5,0,0,0-11.3,2.6c9.4-28.1,20.1-55.8,32-82.9l-11.3-.5c2.5-9.7,20.1-72.4,28.7-89.9L982,102l-9.2,2.5c6.7,26.1,13,52.2,20.6,77.9-3.2,0-11,.3-11.4.6s9.5,20.7,13.4,30.7,12.7,20.3,18.5,31.2"
        fill="none"
        stroke="#4db6c1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Group>
  );
}
