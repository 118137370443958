module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Commonwealth - Emergency Savings Demo","short_name":"commonwealth","start_url":"/","background_color":"#2559a7","theme_color":"#2559a7","display":"minimal-ui","icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1432a5708345d9eef33f45c788e17708"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo/packages/site"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../theme/gatsby-browser.js'),
      options: {"plugins":[],"sources":{"steps":"/opt/build/repo/packages/site/src/steps","partials":"/opt/build/repo/packages/site/src/partials"},"routes":{"confirmation":"/confirmation/"}},
    }]
